import { AxiosError, AxiosRequestHeaders } from 'axios';

import { UseMutationOptions, useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { handleErrors } from '@hl-portals/helpers';
import { config } from '@hl-portals/helpers';

const PDF_MERGE_URL = config.pdfMergerUrl;
const PDF_MERGE_API_KEY = config.pdfMergeApiKey;

type UseMergePdfPayload = {
  outputName: string;
  fileKeys: string[];
};

type UseMergePdfOptions = UseMutationOptions<
  unknown,
  AxiosError,
  UseMergePdfPayload
>;

export const useMergePdf = (options?: UseMergePdfOptions) => {
  return useMutation(
    async ({ outputName, fileKeys }: UseMergePdfPayload) => {
      const headers: AxiosRequestHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': PDF_MERGE_API_KEY,
      };

      const res = await http.public.post(
        PDF_MERGE_URL,
        {
          output_name: outputName,
          s3Keys: fileKeys,
        },
        {
          headers,
        }
      );
      return res.data;
    },
    {
      ...options,
      onError: (error, variables, context) => {
        handleErrors(error as AxiosError);
        options?.onError?.(error, variables, context);
      },
    }
  );
};
