import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../../constants/endpoints';
import { TaskPayload } from '../../../types/task';
import { FETCH_TASK_QUERY_KEY } from '../use-fetch-task';

export const useUpdateTask = (
  options?: UseMutationOptions<unknown, unknown, TaskPayload>
) => {
  const client = useQueryClient();

  return useMutation(
    async ({
      token,
      attachable_id,
      attachable_type = 'Lead',
      snapshot_uuid,
      template_slug,
    }: TaskPayload) => {
      return http.public.post(
        endpoints.task_service.v2.tasks.update_attachable_task,
        {
          token,
          attachable_id,
          attachable_type,
          template_slug,
          metadata: {
            snapshot_uuid,
          },
        }
      );
    },
    {
      onSuccess: (...args) => {
        client.invalidateQueries({
          queryKey: FETCH_TASK_QUERY_KEY,
        });
        options?.onSuccess?.(...args);
      },
      ...options,
    }
  );
};
