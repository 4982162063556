import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../../constants/endpoints';
import { Task, TaskPayload, TaskResponse } from '../../../types/task';

export const FETCH_TASK_QUERY_KEY = 'FETCH_TASK';

type UseFetchTaskArgs = Pick<
  TaskPayload,
  'token' | 'attachable_id' | 'template_slug'
>;

export const useFetchTask = (
  { token, attachable_id, template_slug }: UseFetchTaskArgs,
  options?: UseQueryOptions<Task>
) => {
  return useQuery<Task>(
    [FETCH_TASK_QUERY_KEY, token, attachable_id, template_slug],
    async () => {
      const res = await http.public.get<TaskResponse>(
        endpoints.task_service.v2.tasks.fetch_attachable_task,
        {
          params: {
            token,
            attachable_id,
            attachable_type: 'Lead',
            template_slug,
          },
        }
      );
      return res.data.data.attributes;
    },
    options
  );
};
