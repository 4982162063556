import { UseMutationOptions, useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../../../shared/constants/endpoints';
import { TaskPayload } from '../../../types/task';

export const useCompleteTask = (
  options?: UseMutationOptions<unknown, unknown, TaskPayload>
) => {
  return useMutation((payload: TaskPayload) => {
    return http.public.post(
      endpoints.task_service.v2.tasks.complete_attachable_task,
      payload
    );
  }, options);
};
