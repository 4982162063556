import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { dataFormatter } from '@equity/shared/constants/data-formatter';
import { endpoints } from '@equity/shared/constants/endpoints';

export type LeadDataRole = 'Contract Advisor' | 'Listing Operations Specialist';

export type LeadData = {
  type: 'bbys_lead';
  id: string;
  active: boolean;
  agent: {
    name: string;
    email: string;
    phone: string;
  };
  additional_client: {
    name?: string;
    email?: string;
    phone?: string;
  };
  departing_property_full_address: string;
  lead_id: number;
  loan_officer: {
    id: string;
    name: string;
    company_name: string;
  };
  loan_officer_assistant: {
    name: string;
    email: string;
    phone: string;
  };
  client: {
    name: string;
    email: string;
    phone: string;
  };
  required_documents: {
    title: string;
    description?: string;
    notes?: string;
  }[];
  transaction_team: {
    role: LeadDataRole;
    name: string;
    phone: string;
    email: string;
    zoom_link?: string;
  }[];
  tasks: string[]; // TODO: Change for real type
  dp_max_downpayment_equity: string;
  equity_boost: boolean;
  equity_boost_amount: string;
  partner_slug: string | null;
  state_code: string;
};

export const FETCH_LEAD_DATA_QUERY_KEY = 'FETCH_LEAD_DATA';

export const useLeadData = (
  token: string,
  options?: UseQueryOptions<LeadData>
) =>
  useQuery<LeadData>(
    [FETCH_LEAD_DATA_QUERY_KEY, token],
    async () => {
      return http.public
        .get(`${endpoints.lead_data_service.v2.bbys.current}?token=${token}`)
        .then(({ data }) => dataFormatter.deserialize(data) as LeadData);
    },
    options
  );
