import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { useRouter } from 'next/router';

import { PageLoading } from '@hl-portals/ui';

import {
  useCreateSnapshot,
  useFetchSnapshot,
  useUpdateSnapshot,
} from '@hl-portals/hooks';

import { InvalidTokenMessage } from '@equity/shared/components/invalid-token-message';

import { Task, TaskPayload } from '../../../types/task';
import { LeadData, useLeadData } from '../../use-lead-data';
import { useCompleteTask } from '../use-complete-task';
import { useFetchTask } from '../use-fetch-task';
import { useUpdateTask } from '../use-update-task';

/**
 * This hook is used to fetch a task and its snapshot following the following steps:
 * 1. Check if task is completed and if it has a snapshot_uuid
 * 2. Fetch snapshot
 * 3. Fetch lead data
 * 4. Create snapshot if it doesn't exist
 * 5. Update task with snapshot_uuid if it doesn't exist in task.metadata
 */

type Options<SnapshotData> = {
  onTaskCompleted?: (task: Task) => void;
  onFetchTaskSuccess?: (task: Task) => void;
  onFetchSnapshotSuccess?: (snapshot: SnapshotData) => void;
  onLeadDataSuccess?: (data: LeadData) => void;
};

export function useTaskWithSnapshot<SnapshotData>(
  createSnapshotPayload: Partial<SnapshotData>,
  taskPayload: TaskPayload,
  options?: Options<SnapshotData>
) {
  const [isTokenValid, setIsTokenValid] = useState(true);

  const { token } = useRouter().query as { token: string };

  // 1. Check if task is completed and if it has a snapshot_uuid

  const { data: task, isLoading: isFetchTaskLoading } = useFetchTask(
    taskPayload,
    {
      onSuccess: (_task) => {
        if (_task.completed_at) {
          options?.onTaskCompleted?.(_task);
        }
        if (!_task.metadata.snapshot_uuid) {
          createSnapshot(createSnapshotPayload);
        }
        options?.onFetchTaskSuccess?.(_task);
        return _task;
      },
      onError: () => {
        setIsTokenValid(false);
      },
      enabled: isTokenValid,
    }
  );

  const isTaskCompleted = !!task?.completed_at;
  const taskSnapshotUUID = task?.metadata?.snapshot_uuid;

  const { mutate: updateTask, isLoading: isUpdateTaskLoading } =
    useUpdateTask();

  const { mutate: completeTask, isLoading: isCompleteTaskLoading } =
    useCompleteTask();

  // 2. Fetch snapshot

  const { data: snapshot, isLoading: isFetchSnapshotLoading } =
    useFetchSnapshot<SnapshotData>(taskSnapshotUUID, {
      onSuccess: (_snapshot) => {
        options?.onFetchSnapshotSuccess?.(_snapshot.attributes.payload);
      },
    });

  const { mutate: createSnapshot, isLoading: isCreateSnapshotLoading } =
    useCreateSnapshot<Partial<SnapshotData>, SnapshotData>({
      onSuccess: (_result) => {
        // 2.1. Update task with snapshot_uuid
        updateTask({
          ...taskPayload,
          snapshot_uuid: _result.uuid,
        });
      },
    });

  const { mutate: updateSnapshot, isLoading: isUpdateSnapshotLoading } =
    useUpdateSnapshot<Partial<SnapshotData>, SnapshotData>(
      taskSnapshotUUID,
      snapshot?.attributes
    );

  // 3. Fetch lead data

  const { data: lead, isLoading: isLeadDataLoading } = useLeadData(token, {
    onSuccess: (_lead) => {
      options?.onLeadDataSuccess?.(_lead);
    },
    onError: () => {
      setIsTokenValid(false);
    },
    enabled: isTokenValid && isTaskCompleted === false,
  });

  return {
    task,
    lead,
    snapshot: snapshot?.attributes,
    updateSnapshot,
    completeTask,
    isTokenValid,
    isTaskCompleted,
    isUpdateSnapshotLoading,
    isCompleteTaskLoading,
    isLoading:
      isFetchTaskLoading ||
      isFetchSnapshotLoading ||
      isCreateSnapshotLoading ||
      isUpdateTaskLoading ||
      isLeadDataLoading,
  };
}

/**
 * This provider is used to provide the task, the snapshot data and the lead data to components.
 * It also provides the isLoading, isTokenValid, isTaskCompleted.
 */

type TaskContextType<SnapshotData = unknown> = ReturnType<
  typeof useTaskWithSnapshot<SnapshotData>
>;

const TaskContext = createContext<TaskContextType | null>(null);

type TaskProviderProps<SnapshotData = unknown> = PropsWithChildren<
  TaskContextType<SnapshotData>
>;

export function TaskProvider<SnapshotData = unknown>({
  ...props
}: TaskProviderProps<SnapshotData>) {
  if (props.isLoading) return <PageLoading />;
  if (!props.isTokenValid) return <InvalidTokenMessage />;

  return (
    <TaskContext.Provider value={props}>{props.children}</TaskContext.Provider>
  );
}

export const useTaskContext = <SnapshotData = unknown,>() => {
  const ctx = useContext(TaskContext) as TaskContextType<SnapshotData>;
  if (!ctx) throw new Error('useTask must be used within a TaskProvider');
  return ctx;
};
