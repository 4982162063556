import { config } from '@hl-portals/helpers';

export const endpoints = {
  auth: {
    signIn: `${config.homelightHost}/users/login`,
  },
  lead_data_service: {
    bbys_leads: {
      request_dr_contract:
        '/api/lead-data-service/bbys-leads/request-dr-contract',
    },
    loan_officer_portal: {
      agents: {
        all: '/api/lead-data-service/loan-officer-portal/agents',
      },
      leads: {
        all: '/api/lead-data-service/loan-officer-portal/leads',
        id: (id: string) =>
          `/api/lead-data-service/loan-officer-portal/leads/${id}`,
      },
      lead_users: {
        id: (id: number) =>
          `/api/lead-data-service/loan-officer-portal/lead-users/${id}`,
      },
      tasks: {
        all: '/api/lead-data-service/loan-officer-portal/tasks',
        task_token:
          '/api/lead-data-service/loan-officer-portal/tasks/task-token',
      },
    },
    v2: {
      bbys: {
        id: (id: string) => `/api/lead-data-service/v2/bbys/${id}`,
        current: '/api/lead-data-service/v2/bbys/current',
      },
    },
    v3: {
      leads: {
        id: (id: string, generated_document_id: string) =>
          `api/lead-data-service/v3/leads/${id}/generated-documents/${generated_document_id}/download-pdf`,
      },
    },
  },
  loan_officer_data_service: {
    loan_officer_portal: {
      loan_officers: {
        id: (id: string) =>
          `/api/loan-officer-data-service/loan-officer-portal/loan-officers/${id}`,
      },
      users: {
        all: '/api/loan-officer-data-service/loan-officer-portal/users',
      },
    },
  },
  mfa_service: {
    verifications: {
      public: {
        create: '/api/mfa-service/verifications/public',
        id: (id: string) => `/api/mfa-service/verifications/public/${id}`,
      },
      id: (id: string) => `/api/mfa-service/verifications/${id}`,
    },
  },
  task_service: {
    v2: {
      tasks: {
        fetch_attachable_task:
          '/api/task-service/v2/tasks/fetch-attachable-task',
        update_attachable_task:
          '/api/task-service/v2/tasks/update-attachable-task',
        complete_attachable_task:
          '/api/task-service/v2/tasks/complete-attachable-task',
        status_attachable_task:
          '/api/task-service/v2/tasks/status-attachable-task',
      },
    },
  },
  user_verification_service: {
    verifications: {
      all: '/api/user-verification-service/verifications',
      id: (id: string) => `/api/user-verification-service/verifications/${id}`,
    },
  },
  user_auth_service: {
    users: {
      exists: '/api/user-auth-service/users/exists',
      current: '/api/user-auth-service/users/current',
    },
    user_login: {
      stop_impersonation:
        '/api/user-auth-service/user-login/un-impersonate-user',
      validate_magic_link_token: {
        token: (token: string) =>
          `/api/user-auth-service/user-login/validate-magic-link-token?token=${token}`,
      },
      set_password: '/api/user-auth-service/user-login/set-password',
    },
  },
};
